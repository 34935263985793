<template>
    <div class="viewProInH5Page" id="draggable" v-if="proVisible"
        :style="{ left: posX + 'px', top: posY + 'px', position: 'fixed' }">
        <div class="titleBox">
            鼠标移入可拖动窗口
            <div class="close" @click="closeModel"><i class="el-icon-circle-close"></i></div>
        </div>
        <object :data="iframeUrl" width="100%" height="100%"></object>
    </div>
</template>

<script>
export default {
    data() {
        return {
            spuId: '', // 商品spuId
            iframeUrl: '', // iframeUrl
            proVisible: false, // 弹窗 控制
            isDragging: false, // 控制元素移动
            offsetX: null,
            offsetY: null,
            posX: 0, // 元素的左边距
            posY: 0,  // 元素的上边距
            isFuncCall: false, // 是否为函数式挂载
            router: null
        }
    },
    created() {
        if (this.spuId) {
            this.open(this.spuId)
            this.isFuncCall = true
            this.router.beforeEach((to,from,next) => {
                this.closeModel()
                next()
            })
        }
    },
    mounted() {
        // 当组件挂载后，设置初始位置为屏幕中心
        this.setInitialPosition();
    },
    methods: {
        setInitialPosition() {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const elementWidth = 375; // 设定元素的宽度
            const elementHeight = 750; // 设定元素的高度
            this.posX = (windowWidth - elementWidth) / 2;
            this.posY = (windowHeight - elementHeight) / 2;
        },
        mousedownFn(event) { // 鼠标按下时
            this.isDragging = true;
            const rect = this.draggable.getBoundingClientRect();
            this.offsetX = event.clientX - rect.left;
            this.offsetY = event.clientY - rect.top;
        },
        mousemoveFn(event) {
            if (this.isDragging) {
                this.posX = event.clientX - this.offsetX;
                this.posY = event.clientY - this.offsetY;
            }
        },
        mouseupFn() {
            this.isDragging = false;
        },
        initElMove() {
            this.draggable = document.getElementById('draggable');
            this.draggable.addEventListener('mousedown', this.mousedownFn);
            document.addEventListener('mousemove', this.mousemoveFn);
            document.addEventListener('mouseup', this.mouseupFn);
        },
        async open(spuId) {
            this.iframeUrl = this.getIframeUrl(spuId)
            this.proVisible = true;
            await this.$nextTick();
            this.initElMove();
        },
        changePro(spuId) {
            this.iframeUrl = this.getIframeUrl(spuId);
        },
        getIframeUrl(spuId) {
            if (process.env.NODE_ENV === 'development') {
                return `https://h5.thitmall.com/detail/${spuId}?isApp=1`
            }

            let baseUrl = window.location.hostname.replace(/^[^.]+/, 'h5');
            return `https://${baseUrl}/detail/${spuId}?isApp=1`;
        },
        closeModel() {
            this.draggable.removeEventListener('mousedown', this.mousedownFn);
            document.removeEventListener('mousemove', this.mousemoveFn);
            document.removeEventListener('mouseup', this.mouseupFn);
            this.proVisible = false;
        }
    }
}
</script>

<style lang='scss' scoped>
.viewProInH5Page {
    width: 375px;
    height: 785px;
    position: fixed;
    z-index: 999;
    background-color: #FFF;
    box-shadow: 0px 8px 48px 0px rgba(29, 25, 25, 0.2);

    .titleBox {
        height: 35px;
        width: 100%;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        color: #ccc;
        box-shadow: 0px 8px 48px 0px rgba(29, 25, 25, 0.2);
        cursor: move;

        .close {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 25px;
            height: 25px;
            font-size: 24px;
            line-height: 20px;
            // color: #FFF;
            text-align: center;
            cursor: pointer;
        }
    }

    .titleBox:hover {
        background: #757575;

        .close {
            color: #FFF;
        }
    }

    .close:hover {
        color: #FF0023 !important;
    }
}
</style>