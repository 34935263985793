/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: /merchantsys-vue/src/service/apis.js
 */
const isProduction = process.env.NODE_ENV === 'production'
// 测试服务器后面加上sys
const apis = {
  queryShoppingRanking: '/mock/queryShoppingRanking',
  sendEmailCode: `/api/sys/register/sendEmail`,
  queryList: '/api/store/queryList',

  //颜星
  verifyCaptcha: `/api/sys/register/verifyCaptcha`, // 核对验证码
  registerEmailCode: `/api/sys/register/sendEmail`, // 发送邮箱验证码
  addUserInfo: `/api/sys/register/addUserInfo`, // 手机号邮箱注册 ---带密码
  getPhoneCode: `/api/sys/register/sendSms`, // 发送手机验证码
  getGoodsList: `/api/product/productBasic/productList`, // 商品列表展示接口
  productOffShelf: `/api/product/productBasic/productOffShelf`, // 商品下架接口
  delProduct: `/api/product/productBasic/delProduct`, // 商品删除
  // 订单管理页面
  orderManagement: `/api/order/b/order/orderManagement`, // 订单管理列表
  orderDetails: `/api/order/b/orderManagement/orderDetails`, // 订单详情
  updateRemarks: `/api/order/b/orderManagement/updateRemarks`, // 修改备注
  changePrice: `/api/order/order/changePrice`, // 修改实付金额
  companies: `/api/order/logistics/online/companies`, // 在线下单支持物流公司列表
  // Ship: `/api/order/logistics/online`, // 在线物流下单
  Ship: `/api/order/logistics/submit`, // 在线物流下单
  modify: `/api/order/logistics/modify`, // 修改物流订单
  logisticsDetailById: `/api/order/productOrder/logisticsDetailById`, // 订单详情-物流详情
  orderQueryByPickCode: '/api/order/queryByPickCode', // 根据提货码获取订单列表
  orderClickSend: '/api/order/logistics/b/order/clickSend', // 确认提货
  // 商品模块
  batchToShelf: `/api/product/productBasic/batchToShelf`, //草稿商品批量上架
  batchToChange: `/api/product/productBasic/batchToChange`, // 商品管理批量接口
  batchUpdataPrice: '/api/product/b/productBasic/batchToChangeWeb', // 批量修改 零售价 划线价 成本价
  // 工作台
  getStagingData: `/api/order/desk/queryOrderInfo`, // 获取工作台数据
  // 订单发货页面
  queryOrderShipment: `/api/order/orderShipment/b/queryOrderShipment`, // 列表接口
  PrintPickingList: `/api/order/pickList/print`, // 打印拣货单
  PrintSLWB: `/api/order/waybill/print`, // 打印面单和运单
  ApplyForWaybill: `/api/order/waybill/apply`, // 申请运单接口
  mergeShipments: `/api/order/logistics/order/mergeShipments`, // 合并发货
  CancelShipment: `/api/order/logistics/cancel`, // 取消发货
  remarkPickList: `/api/order/pickList/remark`, // 标记拣货单
  complete: `/api/order/pickList/complete`, // 备货完成
  getFreightPre: `/api/order/waybill/freightPre`, //获取运单运费和查询物流公司名称
  // 财务中心
  merchantFinanceDetail: '/api/order/orderFinance/merchant/financeDetail', // 商家收支明细



  //汪文升
  // --------登录忘记密码模块
  login: '/api/oauth/token', // 登录授权 --带密码
  getScanCode: '/api/sys/register/getScanCode', // 获取唯一识别码
  getLogin: '/api/sys/register/getLogin', //获取移动端是否扫码确认了
  modiyPassWord: '/api/sys/register/modiyPassWord', // 重置密码 ---带密码
  // --------商品模块
  getProductList: '/api/product/productBasic/query', // 商品列表
  addLabel: '/api/product/productParamDict/insertParam', // 新增属性名 参数/规格  paramType 1 参数  2 规格
  addvalue: '/api/product/productParamDict/updateParamValue', // 新增属性值 参数/规格  paramType 1 参数  2 规格
  addEditProduct: '/api/product/productBasic/insertProduct', // 新增商品及编辑
  getCategoryTree: '/api/product/productCategory/tree', // 类目数据
  getProductBrands: '/api/product/productCategory/productBrands', // 类目数据
  getParamDict: '/api/product/productParamDict/queryParam', // 查询参数、规格名称和值
  addBrand: '/api/product/productBrandInfo/insertBrandInfo', // 新增品牌
  getProductDetail: '/api/product/productBasic/queryProductOne', // 获取商品详情
  getCategoryBrands: '/api/product/productCategory/queryBrandName', // 查对应类目关联的品牌
  getFreightMethod: '/api/sys/cxFreightTemplate/queryFreightInfoByStoreId', // 获取对应商品得店铺运费模板
  getGroupTableData: '/api/product/productGroup/queryGoodsCenter', // 分组列表数据
  updateEnabled: '/api/product/productGroup/enabledType', // 禁用启用分组
  delGroup: '/api/product/productGroup/delGoods', // 分组删除
  getGroupTree: '/api/product/productGroup/goodsTree', // 分组数据
  // getStoreFirstGroup: '/api/product/storeClassification/selectStoreFirstLevelClassification', // 店铺一级分组list
  getStoreFirstGroup: '/api/product/storeClassification/selectStoreClassificationTree', // 店铺一级分组list
  getStoreFirstChildGroup: '/api/product/storeClassification/selectStoreClassificationSub', // 获取店铺一级分组下二三级分组
  addEditFirstGroup: '/api/product/storeClassification/saveStoreFirstLevelClassification', // 编辑新增一级分类
  // saveChildGruop: '/api/product/storeClassification/saveStoreClassificationSub', // 保存店铺子集分类
  saveChildGruop: '/api/product/storeClassification/saveStoreClassification', // 保存店铺子集分类 
  delStoreGroup: '/api/product/storeClassification/delStoreClassification', // 删除当前店铺指定id分组
  getGroupPro: '/api/product/storeClassification/selectStoreClassificationProduct', // 获取对应分组下关联的商品集合
  storeBalance: '/api/order/orderStoreSettleHis/balance', // 店铺余额
  storeRecord: '/api/order/orderStoreDepositHis/depositPage', // 提现记录
  depositApply: '/api/order/orderStoreDepositHis/depositApply', // 提现申请
  storeBankAccount: '/api/sys/cxStoreFinanceAccount/query', // 获取银行账户信息
  saveBankAccount: '/api/sys/cxStoreFinanceAccount/saveOrUpdate', // 获取银行账户信息

  // 刘李
  submit: '/api/sys/companyStoreInfo/submit',  // 提交店铺注册信息
  saveRegisterStoreInformation: '/api/sys/companyStoreInfo/save', // 保存店铺注册信息
  queryList: '/api/sys/store/queryList',
  validateDeduplication: '/api/sys/companyStoreInfo/validateDeduplication', // 公司信息(校验商业登记证以及社会统一信用代码去重)
  selectByAdminInfo: '/api/sys/storeManage/selectByAdminInfo', // 查询店铺信息
  saveAndUpdateStoreInfo: '/api/sys/storeManage/saveAndUpdateStoreInfo', // 编辑店铺信息
  submitStoreRecordInfo: '/api/sys/storeModifyInfo/submitStoreRecordInfo', // 商家修改店铺信息提交
  saveStoreSampleInfo: '/api/sys/storeManage/saveStoreSampleInfo', // 编辑打烊信息
  closeStore: '/api/sys/storeManage/closeStore', // 关店
  cancelClose: '/api/sys/storeManage/cancelClose', // 取消打烊
  sendStoreRegisterEmailCode: '/api/sys/store/sendEmailCode', // 企业信息和店铺认证发送邮箱验证码
  sendPhoneCode: 'api/sys/store/sendPhoneCode', // 发送手机验证码
  validateEmailCode: '/api/sys/store/validateEmailCode', // 邮箱验证码校验
  queryStatusInfo: '/api/sys/queryUserInfo', // 查询用户认证状态
  queryCompanyStoreAuthInfo: '/api/sys/companyStoreInfo/queryCompanyStoreAuthInfo', // 查询店铺注册信息
  queryCompanyInformation: '/api/sys/information/queryCompanyInformation', // 查询企业信息
  queryCompanyInfo: '/api/sys/companyStoreInfo/queryCompanyInfo', // 商家端查询个人企业信息
  submitCompanyRecordInfo: '/api/sys/companyModifyInfo/submitCompanyRecordInfo', // 商家修改企业信息提交
  queryStoreInfo: '/api/sys/storeInfo/queryStoreInfo', // 商家端查询个人店铺信息（商家端）
  updateInformation: '/api/sys/information/updateInformation', // 修改企业信息
  validatePhoneCode: '/api/sys/store/validatePhoneCode', // 校验手机验证码
  reviewInformationList: '/api/sys/merchantCenter/reviewInformationList', // 店铺认证审核详情列表
  nodeFirstTree: '/api/product/productCategory/nodeFirstTree', // 一级商品类目(展示启用的数据)
  submitAuditInfo: '/api/product/cxStoreAuthorization/submitAuditInfo', // 提交资质
  queryOne: '/api/product/cxStoreAuthorization/queryOne', // 查看店铺资质-品牌授权
  queryAuditLog: '/api/product/cxStoreAuthorization/queryAuditLog', // 查看店铺资质审核详情
  authorizationProductBrands: '/api/product/productCategory/authorizationProductBrands', // 获取需要授权商品品牌列表
  verifyAuthorize: '/api/product/productBasic/verifyAuthorize', // 判断类目是否需要授权

  // 审核api
  storeMerchantList: '/api/sys/merchantCenter/storeMerchantList', // 店铺认证列表
  storeReviewList: '/api/sys/merchantCenter/storeReviewList', // 店铺第一次认证详情
  approvedAndReject: '/api/sys/merchantCenter/approvedAndReject', // 审核通过/驳回
  enterpriseReviewList: '/api/sys/merchantCenter/enterpriseReviewList', // 企业信息审核列表
  storeCompanyAdminRecord: '/api/sys/merchantCenter/storeCompanyAdminRecord', // 企业信息审核详情
  companyApprovedAndReject: '/api/sys/merchantCenter/companyApprovedAndReject', // 企业信息审核通过/驳回
  storeInfoList: '/api/sys/merchantCenter/storeInfoList', // 店铺审核列表
  storeRecordInfoList: '/api/sys/merchantCenter/storeRecordInfoList', // 店铺信息详情
  storeApprovedAndReject: '/api/sys/merchantCenter/storeApprovedAndReject', // 店铺信息审核通过/驳回
  findLogisticsManageInfo: '/api/sys/cxLogistics/queryLogisticsManageInfo', // 查询物流信息
  saveAndUpdateLogisticsManageInfo: '/api/sys/cxLogistics/saveAndUpdateLogisticsManageInfo', // 编辑通用设置
  queryPageInfoByStoreId: '/api/product/cxStoreAuthorization/queryPageInfoByStoreId', // 根据状态分页查询状态资质列表

  // 物流中心
  saveAndUpdateFreightTemplate: '/api/sys/cxFreightTemplate/saveAndUpdateFreightTemplate', // 新建运费模板
  paginationList: '/api/sys/cxFreightTemplate/paginationList', // 查询运费模版列表
  editFreightModule: '/api/sys/cxFreightTemplate/editFreightModule', // 查询运费模版详情
  delFreightModule: '/api/sys/cxFreightTemplate/delFreightModule', // 根据id删除运费模版
  queryFreightModule: '/api/sys/cxLogistics/queryFreightModule', // 查询是否已配置默认运费模版
  setUpFreightTemplate: '/api/sys/cxFreightTemplate/setUpFreightTemplate', // 设置为默认模版

  // 数据中心
  allDataView: '/api/order/stats/overview', // 数据总览
  transactionView: '/api/order/stats/transactionOverview', // 交易概况
  productOverview: '/api/product/stats/productOverview', // 商品洞察-商品概况
  productPayAmountRanking: '/api/product/stats/productPayAmountRanking', // 商品洞察-支付金额TOP
  productVisitsNumRanking: '/api/product/stats/productVisitsNumRanking', // 商品洞察-访问数TOP
  storeOverview: '/api/sys/stats/storeOverview', // 店铺洞察-店铺概况
  storeRanking: '/api/sys/stats/storeRanking', // 店铺洞察-店铺排行榜

  // 财务中心
  freightPage: '/api/order/orderStoreFreightSettleHis/freightPage', // 门店运费月结历史查询
  freightDetailPage: `/api/order/orderStoreFreightSettleHis/freightDetailPage`, // 详情分页
  freightConfirm: `/api/order/orderStoreFreightSettleHis/freight/confirm`, // 月结单确认
  cycleSettleQuery: `/api/order/orderStoreSettleHis/cycleSettleQuery`, // 对账报表查询
  uploadFile: `/api/sys/anon/c/upload`, // 上传文件

  registerModifyUserAccount: '/api/sys/register/c/modifyUserAccount', // 手机号换绑
  exportOrderData: '/api/order/order/merchant/download', // 导出订单
  orderUpload: '/api/order/logistics/orderLogis/upload', // 导入订单
  orderUploadErrorDownload: '/api/order/logistics/orderLogis/download/error', // 导入订单失败导出
  OrderDispatchExport: '/api/order/orderShipment/merchant/download', // 发货管理订单导出
  skuCanDisableCheck: '/api/product/productBasic/productSkuOffShelf', // sku是否可以禁用查询
}

export default apis
